@use "./variables" as var;
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$trade-web-platform-primary: mat.define-palette(
  mat.$blue-palette,
  A200,
  A100,
  A400
);
$trade-web-platform-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$trade-web-platform-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$trade-web-platform-theme: mat.define-light-theme(
  (
    color: (
      primary: $trade-web-platform-primary,
      accent: $trade-web-platform-accent,
      warn: $trade-web-platform-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($trade-web-platform-theme);

// --- Form Styles --- //
.mat-form-field label {
  font-size: var.$font-size-08;
}
.mat-form-field.mat-form-field-should-float label {
  font-size: var.$font-size-05;
}
input.mat-input-element,
.mat-select {
  padding: 8px 0;
}

span.ng-tns-c189-3.ng-star-inserted {
  font-size: 15px;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #ccc;
}
// Forms fields error style overrieds
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field-appearance-legacy .mat-form-field-ripple {
  height: 1px !important;
}

// --- Form Section titles --- //
.form-title {
  color: var.$primary-color;
}
.form-section-title {
  font-size: var.$font-size-06;
}
.form-section-info {
  font-size: var.$font-size-08;
}
.browse {
  font-size: var.$font-size-08;
  color: var.$primary-color;
}

// --- Fonts --- //
.font-light {
  font-family: var.$font-light;
}
.font-regular {
  font-family: var.$font-regular;
}
.font-bold {
  font-family: var.$font-bold;
}
.font-semi-bold {
  font-family: var.$font-semi-bold;
}

// --- Colors --- //
.text-color {
  &.primary {
    color: var.$primary-color;
  }
  &.success {
    color: var.$success-color;
  }
  &.disabled {
    color: #ccc;
  }
}

// --- Buttons --- //
.btn {
  text-transform: uppercase;
  font-family: var.$font-regular;
  &.primary {
    background-color: var.$primary-color;
    color: #ffffff;
  }
  &.danger {
    background-color: var.$failed-color;
    color: #ffffff;
  }
  &.decline {
    background-color: var.$posted-color;
    color: #ffffffef;
  }
  &.large {
    min-width: 198px;
    line-height: 43px;
    font-size: 1.1rem;
  }
  &.w-regular {
    min-width: 120px;
  }
  &.w-mid {
    min-width: 110px;
    line-height: 30px;
    // font-size: 1.1rem;
  }
  &.w-large {
    min-width: 195px;
    line-height: 40px;
    letter-spacing: 1px;
    font-size: 0.8rem;
  }
}

// --- Links --- //
.link {
  text-decoration: none;
  font-size: var.$font-size-08;
  @include var.transitionall(0.5s);

  &.large{
    font-size: var.$font-size-05 !important;
  }

  &.primary {
    color: var.$primary-color;
  }

  &.secondary {
    color: #888;
  }

  &.dark {
    color: #2a2a2a;
  }

  &.underlined {
    text-decoration: underline;
  }

  &.danger {
    color: var.$failed-color;
  }

  &:hover {
    text-decoration: underline;
  }
}

// --- text sizes --- //
.text-xs {
  font-size: var.$font-size-07 !important;
}
.text-small {
  font-size: var.$font-size-06 !important;
}
.text-md {
  font-size: var.$font-size-05 !important;
}
.text-lg {
  font-size: var.$font-size-04 !important;
}

// --- Custom width --- //
.w-80 {
  width: 80%;
}

// --- Background colors --- //
.primary-bg {
  background-color: var.$primary-color;
}

//  Exception handler - Snackbar colors
.success-snackbar {
  font-size: var.$font-size-05;
  font-family: var.$font-regular;
  color: #fff;
  background: var.$success-color;
}

.error-snackbar {
  font-size: var.$font-size-05;
  font-family: var.$font-regular;
  color: #fff;
  background: var.$failed-color;
}

// --- Scrollbar Styles --- //
.no-scroll{
  overflow: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(204, 204, 204, 0.1);
  background: rgba($color: #3c8dcb, $alpha: 0.2);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: rgba($color: #3c8dcb, $alpha: 0.7);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background: rgba($color: var.$primary-color, $alpha: 1);
}

// Custom Dialog ( pop-up ) - border radius : 0
.popup-header {
  font-size: var.$font-size-05;
  font-family: var.$font-bold;
  color: #20232b;
}

.custom-dialog-container .mat-dialog-container {
  padding: 0;

  .mat-dialog-container {
    padding: 0;

    .popup-wr {
      position: relative;
    }
  }
}

// --- Borders --- //
.custom-border {
  &.dashed {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='LightGray' stroke-width='2' stroke-dasharray='4%2c 4' stroke-dashoffset='14' stroke-linecap='square'/%3e%3c/svg%3e");
  }
  &.stroke{
    stroke: red;
  }
}


// animatioons
.animate-all{
  @include var.transitionall(0.3s)
}

.center-img{
  object-fit: cover;
}

.gradient-bg{
  background: rgb(33,150,243);
  &.horiz{
    background: linear-gradient(99deg, rgba(21, 101, 192, 1) 0%,  rgba(33,150,243, 1) 100%);
  }
  &.vert{
    background: linear-gradient(180deg, rgba(1, 128, 255, 1)  0%,  rgba(57, 204, 242, 1) 100%);
  }
}


// tabs
.mat-tab-label {
  height: 35px !important;
  min-width: 25px !important;
  padding: 10px !important;
  // background-color: transparent;
  color: var.$text-color-01 !important;
  font-weight: 700;
  font-size: var.$font-size-09;

  /* Styles for the active tab label */
  &.mat-tab-label-active {
    min-width: 25px !important;
    padding: 10px;
    // background-color: transparent;
    color: var.$text-mat-label-active !important; 
    font-weight: 700;
  }
}

.tab-inner{
  min-height: 300px;
}

.required{
  color: #FF0000;
  font-family: var.$font-bold;
  font-size: 16px;
}