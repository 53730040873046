@font-face {
  font-family: 'source-sans-pro-regular';
  src: url('/assets/fonts/source-sans-pro/SourceSansPro-Regular.otf') format("opentype");
}

@font-face {
  font-family: 'source-sans-pro-light';
  src: url('/assets/fonts/source-sans-pro/SourceSansPro-Light.otf') format("opentype");
}

@font-face {
  font-family: 'source-sans-pro-bold';
  src: url('/assets/fonts/source-sans-pro/SourceSansPro-Bold.otf') format("opentype");
}

@font-face {
  font-family: 'source-sans-pro-semi-bold';
  src: url('/assets/fonts/source-sans-pro/SourceSansPro-Semibold.otf') format("opentype");
}