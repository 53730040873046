$link-color: #1E88E5;
$link-hover-color: #1565C0;
$link-hover-decoration: none;
$label-margin-bottom: 0;

// Colors
$primary-color: #39CCF2;
$primary-hover-color: #1565C0;
$primary-btn-color: #088afd;
$req-pending-color: #8a8a8a;
$success-color: #0ec983;
$posted-color: #8a8a8a;
$expired-color: #cccccc;
$failed-color: #fd5960;
$inprogress-color: #ba6ada;
$pending-color: #ffa25c;

$emergency-color: #CE1A1A;
$scheduled-color: #FFA25C;
$info-color: #43c3eb;

// Text colors
$text-primary: #1E88E5;
$text-primary-light: #2992ee;
$text-color-faild: $failed-color;
$text-color-secondary: #696969;
$text-color-dark: #2a2a2a;

// Text colors
$text-color-01: #3A3A3A;
$text-form-title: #121212;
$text-breadcrumb: #088AFD;
$text-mat-label-active:#227CDA;
// Text colors
// $text-color-01: #4D4F5C;
$text-color-02: #666666;
$text-color-03: #707070;
$btn-light-color: #f5f5f6;

// Fonts
$font-regular: 'source-sans-pro-regular', 'arial', sans-serif;
$font-light: 'source-sans-pro-light', 'arial', sans-serif;
$font-bold: 'source-sans-pro-bold', 'arial', sans-serif;
$font-semi-bold: 'source-sans-pro-semi-bold', 'arial', sans-serif;

$card-font-bold:'Lucida Sans Typewriter Bold';
$card-font-regular:'Lucida Sans Typewriter Regular';

// Font sizes
$font-size-01 : 1.8rem;
$font-size-02 : 1.6rem;
$font-size-03A : 1.5rem;
$font-size-03 : 1.4rem;
$font-size-04 : 1.2rem;
$font-size-05A : 1.1rem;
$font-size-05 : 1rem;
$font-size-06 : 0.9rem;
$font-size-07 : 0.85rem;
$font-size-08 : 0.8rem;
$font-size-09 : 0.75rem;
$font-size-10 : 0.7rem;
$font-size-11 : 0.65rem;


@mixin transitionall($delay) {
    -webkit-transition: all $delay ease;
    -moz-transition: all $delay ease;
    -ms-transition: all $delay ease;
    -o-transition: all $delay ease;
    transition: all $delay ease;
}

@mixin transition($transition) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}

@mixin transform($property) {
    -webkit-transform: $property;
    -ms-transform: $property;
    transform: $property;
}