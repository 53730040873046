* {
  &:active,
  :focus {
    outline: none !important; // 1
  }
}

a:not(.mat-button):not(.mat-raised-button):not(.mat-fab):not(.mat-mini-fab):not(.mat-list-item) {
  // color: #3f51b5; // 2
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  resize: none;
}
