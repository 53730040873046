@import "fonts";
@import "variables";
@import "~bootstrap/scss/bootstrap-grid"; // add css grid
@import "~bootstrap/scss/bootstrap-reboot"; // add css reboot
@import "~bootstrap/scss/bootstrap-utilities"; // add css utilities
@import "reset"; // Bootstrap - Material conflict fix

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: $font-regular;
  // background-color: #f4f6f9;
}

h1 {
  font-size: $font-size-01;
}

h2 {
  font-size: $font-size-03;
}

a {
  text-decoration: none;
}

.pointer{
  cursor: pointer;
}

.custom-error-color{
  color: #f44336 !important;
}

// Bredcrumb
.bredcrumb{
  .item{
    color: $text-color-01;
    font-size: 0.95rem;
    font-family: $font-semi-bold;
    text-decoration: none;

    &.active{
      color: $text-breadcrumb;
      pointer-events: none;
    }
    &.home{
      color: $text-color-01;
    }
    &:hover{
      color: $primary-hover-color;
    }
  }
}

.bg-custom{
  background-color: hsl(0, 0%, 94%);
  color: rgb(179, 179, 179);
}